'use client'

import dynamic from "next/dynamic";

import { motion, useInView } from 'framer-motion'
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';

const DotLottieReact = dynamic(() => import("@lottiefiles/dotlottie-react").then((mod) => mod.DotLottieReact), { ssr: false });

export const LottiePlayer = ({
  url,
  className,
  addZTranslation
}) => {
  const [dotLottie, setDotLottie] = useState(null);
  const ref = useRef()

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  const inView = useInView(ref, {
    amount: 0.5
  })

  useEffect(() => {
    if (dotLottie) {
      inView ? dotLottie.play() : dotLottie.stop() // `stop()` releases memory
    }
  }, [inView, dotLottie])  

  return (
    <motion.div
      ref={ref}
      className={cn(
        'absolute inset-0 w-full h-full rounded-lg', className
      )}
      style={addZTranslation ? {
        transform: 'translateZ(30px)'
      } : {}}
    >
      <DotLottieReact
        className="bg-transparent w-full h-full"
        src={url}
        loop
        autoplay
        dotLottieRefCallback={dotLottieRefCallback}
      />
    </motion.div>
  )
}